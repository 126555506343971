const FetchTransport = {
  shouldUse() {
    return window.fetch;
  },

  performRequest(url, xmlString, options) {
    const fetchOptions = {
      method: options.method || "POST",
      headers: {
        Accept: "application/xml",
        "Content-Type": "application/xml"
      }
    };

    if(options.method !== "GET") {
      fetchOptions.body = xmlString;

      if(url.startsWith("/")) {
        const csrfMeta = document.querySelector("meta[name=csrf-token]");
        if(csrfMeta != null && csrfMeta.content != null) {
          fetchOptions.headers["X-CSRF-Token"] = csrfMeta.content;
        }
      }
    }

    if(options.user) {
      const {
        user
      } = options;

      if(user.username && user.password) {
        fetchOptions.headers["Authorization"] = `Basic ${btoa(`${user.username}:${user.password}`)}`;
      }

      if(user.sessionId) {
        fetchOptions.headers["X-Kt-Sessionid"] = user.sessionId;
      }
    }

    return fetch(url, fetchOptions).then((response) => {
      if(response.ok) {
        return response.text();
      }else{
        return Promise.reject(response);
      }
    }).then((responseText) => {
      return new DOMParser().parseFromString(responseText, "application/xml");
    });
  }
};

export default FetchTransport;
