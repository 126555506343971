import PropTypes from "prop-types";
import React, { useRef, useState, useEffect, useMemo, useLayoutEffect } from "react";
import ReactDOM from "react-dom";

import { addToListComponentForCheckType } from "kt_jsgem/kt_editor/menu/add_alternative_to_list";

class MenuItemComponent extends React.Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    if (!this.props.readOnly) {
      this.props.onReplaceAnnotation(this.props.id, this.props.alternative);
    }
  }

  render() {
    return (
      <span
        className={this.props.readOnly ? "suggestion readonly" : "suggestion"}
        onClickCapture={this.handleClick}>
        {this.props.title}
      </span>
    );
  }
}

MenuItemComponent.propTypes = {
  readOnly: PropTypes.bool,
  onReplaceAnnotation: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  alternative: PropTypes.string.isRequired
};

class MenuComponent extends React.Component {
  render() {
    const { visible, annotation } = this.props;

    if (!visible || !annotation) {
      return (
        <div className="kt-menu" />
      );
    }

    const {
      annotationType: {
        checkType,
        display: {
          name: annotationTypeName,
          description: annotationTypeDescription
        },
        removableWordCategory
      },
      annotation: {
        alternatives,
        id,
        text,
        typeId,
        reason
      },
      config,
      onAddToPersonalList,
      onIgnoreAnnotation,
      onReplaceAnnotation,
      onDisplayMessage,
      position,
      translations
    } = this.props;

    const alternativeComponents = alternatives.map((alternative) =>
      <MenuItemComponent
        key={alternative.alternative}
        readOnly={alternative.readOnly}
        id={id}
        alternative={alternative.alternative}
        title={alternative.alternative}
        onReplaceAnnotation={onReplaceAnnotation}
      />
    );

    const AddToListMenuItemComponent = addToListComponentForCheckType(checkType);

    return (
      <div className="kt-menu -visible" style={position}>
        <h2 className="title">{annotationTypeName}</h2>
        <p className="description">{annotationTypeDescription}</p>
        {alternativeComponents}
        {config.ignoreButtons &&
        <MenuItemComponent
          readOnly={false}
          id={id}
          alternative={text}
          title="Negeer instantie"
          onReplaceAnnotation={onIgnoreAnnotation}
        />
        }
        {config.addToPersonalList && removableWordCategory &&
        <AddToListMenuItemComponent
          id={id}
          text={text}
          reason={reason}
          typeId={typeId}
          translations={translations}
          onAddToList={onAddToPersonalList}
          onDisplayMessage={onDisplayMessage}
        />
        }
      </div>
    );
  }
}

MenuComponent.propTypes = {
  annotation: PropTypes.shape({
    id: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    typeId: PropTypes.string.isRequired,
    alternatives: PropTypes.arrayOf(
      PropTypes.shape({
        alternative: PropTypes.string.isRequired,
        readOnly: PropTypes.bool.isRequired
      })
    ).isRequired,
    reason: PropTypes.string
  }),
  annotationType: PropTypes.shape({
    checkType: PropTypes.string.isRequired,
    removableWordCategory: PropTypes.bool,
    display: PropTypes.shape({
      name: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired
    }).isRequired
  }),
  config: PropTypes.shape({
    ignoreButtons: PropTypes.bool,
    addToPersonalList: PropTypes.bool
  }).isRequired,
  onAddToPersonalList: PropTypes.func.isRequired,
  onIgnoreAnnotation: PropTypes.func.isRequired,
  onReplaceAnnotation: PropTypes.func.isRequired,
  onDisplayMessage: PropTypes.func.isRequired,
  position: PropTypes.object,
  translations: PropTypes.object.isRequired,
  visible: PropTypes.bool
};

const useMenuPosition = ({ dimensions, focusedAnnotation, element }) => {
  const [menuOffsetHeight, setMenuOffsetHeight] = useState(0);

  const menuAboveAnnotation = useMemo(() => {
    if(!dimensions) {
      return false;
    }
    return (
      (dimensions.top + dimensions.height + menuOffsetHeight) > window.document.documentElement.clientHeight
    );
  }, [dimensions, menuOffsetHeight]);

  const position = {};
  if(dimensions) {
    position.left = dimensions.left;
    if(menuAboveAnnotation) {
      position.top = dimensions.top - menuOffsetHeight;
    } else {
      position.top = dimensions.top + dimensions.height;
    }
  }

  useLayoutEffect(() => {
    if(!element) {
      return;
    }
    const menu = element.getElementsByClassName("kt-menu")[0];
    if(menu) {
      setMenuOffsetHeight(menu.offsetHeight);
    }
  }, [element, focusedAnnotation, dimensions]);

  return position;
};

const MenuWrapper = ({ focusedAnnotation, focusedAnnotationType, dimensions, onReplaceAnnotation, onIgnoreAnnotation, onAddToPersonalList, onDisplayMessage, configContextMenu, translations, onUpdateMenuElement }) => {
  const menuElement = useRef(null);
  useEffect(() => {
    menuElement.current = document.createElement("div");
    window.document.body.appendChild(menuElement.current);
    onUpdateMenuElement(menuElement.current);
  }, []);

  const position = useMenuPosition({
    dimensions,
    focusedAnnotation,
    element: menuElement.current
  });

  if(menuElement.current == null) {
    return null;
  }

  return (
    ReactDOM.createPortal(
      <MenuComponent
        position={position}
        annotation={focusedAnnotation}
        annotationType={focusedAnnotationType}
        onReplaceAnnotation={onReplaceAnnotation}
        onIgnoreAnnotation={onIgnoreAnnotation}
        onAddToPersonalList={onAddToPersonalList}
        onDisplayMessage={onDisplayMessage}
        config={configContextMenu}
        translations={translations}
        visible={focusedAnnotation != null}
      />
      , menuElement.current
    )
  );
};

export { MenuWrapper };
